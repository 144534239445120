import { Modal, Textarea } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import useAsesmen from "../../stores/asesmen";
import parse from "html-react-parser";
import { request } from "../../api/config";
import Countdown from "../../components/countdown";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
// import { Editor } from "@tinymce/tinymce-react";

const TestSheet = ({ progres }) => {
  // const {fetchNumbers} = useQuestion((state) => state);

  const [question, setQuestion] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // const [questionId, setQuestionId] = useState(progres?.questionId);
  const [showConfirm, setShowConfirm] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answer, setAnswer] = useState(null);

  const forms = useForm();

  // const editorRef = useRef(null);

  // const handleSaveAnswer = async () => {
  //   setLoadingSubmit(true);
  //   let payload = {
  //     testId: progres?.testId ?? null,
  //     value: "",
  //   };
  //   await request
  //     .post("store-docs-temp-answer", payload)
  //     .then((res) => {})
  //     .finally(() => {
  //       setLoadingSubmit(false);
  //     });
  // };

  const handleSaveAnswer = forms.handleSubmit(async (data) => {
    setLoadingSubmit(true);
    let payload = {
      testId: progres?.testId ?? null,
      value: data,
    };
    await request
      .post("store-docs-temp-answer", payload)
      // .then((res) => {})
      .finally(() => {
        setLoadingSubmit(false);
      });
  });

  const getTempAnswer = async () => {
    try {
      const { data } = await request.get(
        `get-docs-temp-answer?sessionId=${progres?.sessionId ?? ""}&testId=${
          progres?.testId ?? ""
        }`
      );

      // Check if data exists and has answers
      if (data?.answer) {
        // Loop through the numbered keys (e.g., "0", "1", etc.)
        Object.entries(data.answer).forEach(([index, answer]) => {
          // Loop through each key-value pair inside the numbered answer object
          Object.entries(answer).forEach(([key, value]) => {
            // Set the value in the form dynamically
            forms.setValue(`${index}.${key}`, value);
          });
        });
      }
    } catch (error) {
      console.error("Error fetching temp answers:", error);
    }
  };

  const getExpiredAt = () => {
    let date = new Date(progres?.expiredAt ?? null);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let adjustTimezone = date.toLocaleString("en-US", {
      timeZone: timezone,
    });

    return adjustTimezone;
  };

  const expiredAt = getExpiredAt();

  const handleSubmitAnswer = forms.handleSubmit(async (data) => {
    setLoadingSubmit(true);
    let payload = {
      answer: { value: data },
      testId: progres?.testId,
      sessionId: progres?.sessionId,
    };

    await request
      .post(`save-docs`, payload)
      .then((res) => {
        setLoadingSubmit(false);
        window.location.reload();
      })
      .catch(({ response }) => {
        alert(response?.data?.message);
        setLoadingSubmit(false);
        return;
      });
  });

  const setTimer = () => {
    let expired = new Date(expiredAt).getTime();
    let now = new Date().getTime();
    let distance = expired - now;

    if (distance < 0) {
      handleSubmitAnswer();
    }
  };

  const getQuestion = async () => {
    await request
      .get(`get-all-questions/${progres?.testId}`)
      .then(({ data }) => {
        // setQuestion(data?.data[0]?.question);
        setQuestion(data?.data.map((item) => item.question));
        setAnswers(data?.data.map((item) => item.answer));
      });

    // await handleSaveAnswer();

    return;
  };

  useEffect(() => {
    getQuestion();
    getTempAnswer();
    if (progres?.expiredAt) {
      setInterval(() => {
        setTimer();
      }, 1000);
    }

    return () => {
      clearInterval(setTimer);
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      handleSaveAnswer();
    }, 10000);
  }, []);

  return (
    <>
      <div className="max-w-8xl px-4 p-3 mx-auto relative z-50 h-screen">
        <div className="mt-8 flex">
          <div className="w-full grid grid-cols-2 gap-4">
            <div>
              <div>
                <div className="grid grid-cols-3 gap-2">
                  <div
                    className={twMerge(
                      "mt-4 rounded-lg p-3 cursor-pointer",
                      questionNumber === 0
                        ? "bg-blue-600 text-white"
                        : "bg-white text-gray-400"
                    )}
                    onClick={() => setQuestionNumber(0)}
                  >
                    Pertanyaan 1
                  </div>
                  <div
                    className={twMerge(
                      "mt-4 rounded-lg p-3 cursor-pointer ",
                      questionNumber === 1
                        ? "bg-blue-600 text-white"
                        : "bg-white text-gray-400"
                    )}
                    onClick={() => setQuestionNumber(1)}
                  >
                    Pertanyaan 2
                  </div>
                </div>

                <div className="bg-white border rounded p-5 text-left mt-2 text-2xl leading-normal p-docs">
                  {/* {JSON.stringify(question)} */}
                  {question && question[questionNumber]}
                </div>
              </div>
              <div className="mt-3 text-left overflow-auto">
                {progres?.expiredAt && (
                  <>
                    <p className="text-xs ">Sisa Waktu</p>
                    <Countdown time={expiredAt} type="minuteSecond" />
                  </>
                )}
              </div>
            </div>

            <div className="text-left mt-[10px] h-[90vh] overflow-auto">
              {answers &&
                answers[questionNumber]?.map((item, key) => (
                  <div key={key} className="mb-2">
                    <h3 className="text-lg font-semibold">{item.text}</h3>
                    <Textarea
                      value={forms.watch(`${questionNumber}.${item.key}`) || ""} // Ensure the value corresponds to the correct questionNumber
                      {...forms.register(`${questionNumber}.${item.key}`)} // Properly register the field based on the questionNumber and item key
                      rows={8}
                    />
                  </div>
                ))}
              <div className="flex justify-between items-center mt-2">
                <div className="text-left">
                  {loadingSubmit === true ? (
                    <>
                      <small className="text-green-700">
                        Menyimpan jawaban
                      </small>
                    </>
                  ) : (
                    <>
                      <small>
                        Jawaban anda akan disimpan otomatis setiap 10 detik
                      </small>
                    </>
                  )}
                </div>
                <div className="gap-1 flex">
                  <button
                    onClick={handleSaveAnswer}
                    className="bg-gray-500 text-gray-50 hover:bg-gray-600 text-sm rounded py-2 px-4"
                  >
                    Simpan
                  </button>
                  {questionNumber === 1 && (
                    <button
                      onClick={() => setShowConfirm(true)}
                      className="bg-blue-600 text-white py-2 px-12 text-sm rounded hover:bg-blue-700"
                    >
                      {loadingSubmit === true ? (
                        <>Mengirim Jawaban...</>
                      ) : (
                        <>Kirim Jawaban</>
                      )}
                    </button>
                  )}

                  <Modal
                    show={showConfirm}
                    size="md"
                    popup={true}
                    onClose={() => setShowConfirm(false)}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Apakah anda yakin dengan jawaban anda dan ingin
                          mengirimnya?
                        </h3>
                        <div className="flex justify-center gap-4">
                          {loadingSubmit === true ? (
                            <>
                              <button className="bg-blue-300 text-white rounded py-2 px-16">
                                Mengirim Jawaban...
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={handleSubmitAnswer}
                                className="bg-blue-600 text-white rounded py-2 px-16"
                              >
                                Iya!
                              </button>
                            </>
                          )}
                          <button
                            type="button"
                            className="bg-white border py-2 px-4 rounded"
                          >
                            Tidak
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AsesmenCriticalIncident = () => {
  const { progres } = useAsesmen((state) => state);

  return <TestSheet progres={progres} />;
};

export default AsesmenCriticalIncident;
