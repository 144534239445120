import { useEffect, useState } from "react";

const Countdown = ({time, type}) => {

  const [day, setDay] = useState("00")
  const [hour, setHour] = useState("00")
  const [minute, setMinute] = useState("00")
  const [second, setSecond] = useState("00")
  const [page, setPage] = useState(null);

  const setCountdown = () => {
    let expireAt = new Date(time).getTime();
    let now = new Date().getTime();
    let distance = expireAt - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    setDay(days);
    setHour(hours);
    setMinute(minutes);
    setSecond(seconds);
  }

  useEffect(() => {
    setInterval(() => {
      setCountdown()
    },1000)
  });



  return (
    <>
      <p className="text-2xl leading-none">{hour}:{minute}:{second}</p>
    </>
  )
}

export default Countdown;